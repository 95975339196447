<template>
  <div>
    <div class="borderBox">
      <div class="closeAccountCard">
        <p>可结算总额(无资金方)</p>
        <div class="df moneyBox">
          <h6 class="money">￥{{moneyList.notFunderAmount || 0}}</h6>
          <el-button type="primary" @click="launchCash('no')">发起结算</el-button>
        </div>
        <p class="time">*截止至前一日23:59:59</p>
      </div>
    </div>
    <div class="selectCash">
      <span>资金方：</span>
      <el-select
        ref="formUnitNo"
        @change="selectChange"
        v-model="form.unitNo"
        clearable
        placeholder="请选择资金方"
      >
        <el-option
          v-for="opt in agentList"
          :key="opt.unitNo"
          :value="opt.unitNo"
          :label="opt.name"
        ></el-option>
      </el-select>
    </div>
    <div class="closeAccountCardBox">
       <div class="closeAccountCard">
        <p>可结算总额(有资金方)</p>
        <div class="df moneyBox">
          <h6 class="money">￥{{moneyList.canSeltteAmount || 0}}</h6>
        </div>
        <div>
          <p class="time">*截止至前一日23:59:59</p>
          <p class="time">*可结算金额：已完成资金方还款，可结算的收益</p>
        </div>

      </div>
       <div class="closeAccountCard">
        <p>不可结算总额(有资金方)</p>
        <div class="df moneyBox">
          <h6 class="money">￥{{moneyList.notSeparateAmount || 0}}</h6>
        </div>
        <div>
          <p class="time">*截止至前一日23:59:59</p>
          <p class="time">*不可结算金额：未完成资金方还款，不可结算的收益</p>
        </div>
      </div>
      <el-button type="primary" @click="launchCash('yes')">发起结算</el-button>
    </div>
    <el-form ref="form" :model="searchForm" label-width="120px" :inline="true" class="mt20">
      <el-form-item label="结算记录编号：">
        <el-input v-model="searchForm.number" clearable></el-input>
      </el-form-item>
      <el-form-item label="发起时间：">
        <el-date-picker
          v-model="timeArr"
          value-format="timestamp"
          type="datetimerange"
          range-separator="-"
          start-placeholder="开始时间"
          end-placeholder="结束时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="资金方">
          <el-select
            ref="searchFormUnitNo"
            v-model="searchForm.unitNo"
            clearable
            placeholder="请选择资金方"
          >
            <el-option
              v-for="opt in agentList"
              :key="opt.unitNo"
              :value="opt.unitNo"
              :label="opt.name"
            ></el-option>
          </el-select>
      </el-form-item>
      <el-form-item label="结算状态">
        <el-select ref="searchFormStatus" v-model="searchForm.status" placeholder="请选择结算状态" clearable>
          <el-option
            v-for="item in SETTLE_RESULT"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-button type="primary" class="ml24" @click="querySettleRecord">查询</el-button>
    </el-form>
    <!-- table -->
    <el-table
      class="mt20"
      :data="tableData"
      stripe
      style="width: 100%">
      <el-table-column
        prop="number"
        label="结算记录编号"
        width="180">
      </el-table-column>
      <el-table-column
        prop="statisticsEndTime"
        label="发起时间"
        width="180">
         <template slot-scope="{row}">
          {{row.statisticsEndTime}}
        </template>
      </el-table-column>
      <el-table-column
        prop="settleStatus"
        label="结算状态">
        <template slot-scope="{row}">
          {{$changeObj(SETTLE_RESULT)[row.settleStatus]}}
        </template>
      </el-table-column>
      <el-table-column prop="unitName" label="资金方"/>
      <el-table-column prop="needSettleAccount" label="需结算金额">
        <template v-if="row.needSettleAccount" slot-scope="{row}">
            {{row.needSettleAccount}}元
        </template>
      </el-table-column>
      <el-table-column prop="realitySettleAccount" label="实际结算金额">
          <template v-if="row.realitySettleAccount" slot-scope="{row}">
            {{row.realitySettleAccount}}元
        </template>
      </el-table-column>
      <el-table-column prop="relationOrderQuantity" label="关联订单数量"></el-table-column>
      <el-table-column prop="settleFailOrderQuantity" label="结算失败订单数量"></el-table-column>
      <el-table-column
        prop="endTime"
        label="结算订单时间范围">
        <template slot-scope="{row}">
          {{row.endTime}}
        </template>
      </el-table-column>
      <el-table-column label="操作">
         <template slot-scope="{row}">
          <el-button type="primary" @click="settleExport(row)"  size="small">
            结算订单导出
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        class="mb20 mt20"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        :page-size="pageSize"
        :current-page.sync="beginIndex"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <!-- 发起结算弹窗 -->
  <el-dialog
    title="发起结算"
    :visible.sync="settlementDialogVisible"
    width="30%">
    <div class="df settlementDialog">
      <div class="settlementTitle">结算金额：</div>
      <el-input v-money v-model="form.amount" placeholder="请输入结算金额"></el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="settlementDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="launchCashRequest">确 定</el-button>
    </span>
  </el-dialog>
  </div>
</template>

<script>
import {SETTLE_RESULT} from '@/js/constant';
import {getQuerySettleAmount,getCreateSettleOrder,querySettleRecord,settleExport} from '@/api/closeAccount'
import { queryHaiLiAgent } from "@/api/common";
export default {
  data() {
    return {
      settlementStatus:'',
      SETTLE_RESULT,
      moneyList:[],
      agentList: [], //资金方数据
      total:1,
      tableData:[],
      value1:'',
      inpValue:'',
      form:{//发起结算参数
        unitNo:'',
        amount:'0',
      },
      timeArr:[],
      searchForm:{//查询参数
        unitNo:'',
        status:'',
        number:'',
        endTime:'',
        beginTime:''
      },
      settlementDialogVisible:false,
      beginIndex: 1,
      pageSize:20,
    }
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted(){
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.formUnitNo.blur()
        _this.$refs.searchFormUnitNo.blur()
        _this.$refs.searchFormStatus.blur()
        _this.querySettleRecord()
      }
    };
    this.getQuerySettleAmount();
    this.queryHaiLiAgent();
    this.querySettleRecord()
  },
  methods: {
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    // 分页
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.querySettleRecord();
    },
    // 下拉资金方改变
    selectChange(val){
      this.form.unitNo = val;
      this.getQuerySettleAmount();
    },
     onSearch() {
      this.beginIndex = 1;
      this.querySettleRecord();
    },
      // 结算金额获取
    async getQuerySettleAmount(){
      try{
        const params = {
          unitNo:this.form.unitNo
        }
        const res = await getQuerySettleAmount(params);
        this.moneyList = res.mdata;
        this.searchForm.haiLiUnitNo = res.mdata.haiLiUnitNo;
        this.querySettleRecord();

      }catch(e){
        console.log(e,'e');
      }
    },

    /*获取资金方下拉数据 */
    async queryHaiLiAgent() {
      const params = {
        role: 0
      };
      const res = await queryHaiLiAgent(params);
      this.agentList = res.ldata;
    },


    // 查询
    async querySettleRecord(){
       let page = this.beginIndex;
      page = page - 1;
      try{
        if(this.timeArr && this.timeArr.length !== 0){
          this.searchForm.beginTime = this.timeArr[0];
          this.searchForm.endTime = this.timeArr[1];
        }else{
           this.searchForm.beginTime = null;
          this.searchForm.endTime = null;
        }
        const params = {
          ...this.searchForm,
          beginIndex: page,
          pageSize: this.pageSize
        };
        const res = await querySettleRecord(params);
        this.tableData = res.ldata;
        this.total = res.mdata.total;
      }catch(e){
        console.log(e,'e');
      }
    },
      // 结算弹窗
    launchCash(val){
      this.settlementStatus = val;
      this.settlementDialogVisible = true;
      this.form.amount = '0';
    },
    // 结算弹窗确定
    async launchCashRequest(){

      // 有资金方，选中,资金为0
      const val = this.settlementStatus;
      if(val === 'no' && +this.moneyList.notFunderAmount === 0){
        this.$message({
          type: 'warning',
          message: '当前没有可结算金额!'
        });
        return;
      }
      // 有资金方，
      if(val === 'yes' && +this.moneyList.canSeltteAmount === 0){
        this.$message({
          type: 'warning',
          message: '当前没有可结算金额!'
        });
        return;
      }
      // 有资金方，没选中
      if(val === 'yes' && !this.form.unitNo){
        this.$message({
          type: 'warning',
          message: '请选择一个资金方!'
        });
        return;
      }
      // 有资金方，
      if(val === 'yes' && (this.form.amount > this.moneyList.canSeltteAmount)){
        this.$message.warning('超出可结算金额');
        return;
      }
      // 无资金方
      if(val === 'no' && (this.form.amount > this.moneyList.notFunderAmount)){
        this.$message.warning('超出可结算金额');
        return;
      }
      if(+this.form.amount === 0){
        this.$message.warning('结算金额不能为0');
        return;
      }
      if(val === 'no'){
        this.form.unitNo = this.moneyList.haiLiUnitNo;
      }
        this.form.statisticsTime = this.moneyList.statisticsTime;
        this.getCreateSettleOrder();

    },
    // 创建结算请求(发起结算)
    async getCreateSettleOrder(){
       try{
        const params = {...this.form};
        const res = await getCreateSettleOrder(params);
        this.form = {
            unitNo:'',
            amount:'',
            statisticsTime:''
          }
        this.$message.success('发起结算成功');
        this.settlementDialogVisible = false;
        this.querySettleRecord();
      }catch(e){
        this.form = {
          unitNo:'',
          amount:'',
          statisticsTime:''
        }
      }
    },


    // 结算订单导出
    async settleExport(row){
      try{
        const params = {
          number:row.number
        };
        const res = await settleExport(params);
        if(res.result === 1){
          this.$router.push('/leaseholdMgmt/exportTask');
        }
      }catch(e){
        console.log(e);
      }
    },
  },
  components: {

  }
}
</script>

<style scoped lang="less">
   @import './settlePage.less';

</style>
